<template>
  <div class="contact">
    <header-nav></header-nav>
    <div class="titlePage">
      <h1 class="upper title">Contact</h1>
    </div>
    <main id="container">
      <section>
        <article class="left">
          <h2>Sur les réseaux</h2>
          <div class="logos">
            <a href="https://www.linkedin.com/in/thybault/" target="_blank">
              <img
                src="../assets/images/contact/linkedin.svg"
                alt="Logo de Linkedin"
              />
            </a>
            <a href="https://github.com/ThybaultFr" target="_blank">
              <img
                src="../assets/images/contact/github.svg"
                alt="Logo de Github"
              />
            </a>
            <a href="https://twitter.com/ThybaultFr" target="_blank">
              <img
                src="../assets/images/contact/twitter.svg"
                alt="Logo de Twitter"
              />
            </a>
          </div>
        </article>
        <article class="right">
          <h2>Directement ici !</h2>
          <form @submit.prevent @submit="submit">
            <input
              pattern="([A-z0-9À-ž-\s]){3,}"
              type="text"
              name="name"
              placeholder="Nom"
              required
              v-model="email.name"
            />
            <input
              pattern="(\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b)"
              type="email"
              name="email"
              placeholder="Email"
              required
              v-model="email.email"
            />
            <textarea
              required
              placeholder="Message"
              v-model="email.content"
            ></textarea>
            <button type="submit" :disabled="pending">Envoyer</button>
          </form>
        </article>
      </section>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import "notyf/notyf.min.css";
import HeaderNav from "../components/HeaderNav";
import { Notyf } from "notyf";

export default {
  name: "Contact",
  metaInfo: {
    title: "Contact"
  },
  components: { HeaderNav },
  data() {
    return {
      pending: false,
      email: {
        name: "",
        email: "",
        content: ""
      }
    };
  },
  methods: {
    submit() {
      const notyf = new Notyf();
      this.pending = true;
      let log = {
        email: this.email.email,
        nom: this.email.name,
        message: this.email.content,
        _replyto: this.email.email,
        _subject: "Thybault.fr - Formulaire de contact"
      };
      console.log(log);

      axios
        .post(`https://formsubmit.co/ajax/24ef0a32067bc923282d8d637a6f853f`, {
          email: this.email.email,
          nom: this.email.name,
          message: this.email.content,
          _replyto: this.email.email,
          _subject: "Thybault.fr - Formulaire de contact"
        })
        .then(response => {
          this.pending = false;
          console.log(response);
          if (response.data.success === "true") {
            this.email = [];
            notyf.success({
              message: "C'est envoyé !",
              duration: 9000
            });
          } else {
            notyf.error({
              message: "Une erreur s'est produite !",
              duration: 9000
            });
          }
        })
        .catch(e => {
          this.errors.push(e);
        });
    }
  }
};
</script>

<style scoped>
.titlePage {
  display: flex;
  justify-content: center;
  width: 100%;
}

section {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

h2 {
  margin-bottom: 25px;
}

article {
  width: 550px;
  padding: 25px;
}
article.left .logos {
  display: flex;
  margin-top: 25px;
}
article.left .logos img {
  width: 65px;
  margin-right: 45px;
}

article.right {
  width: 550px;
  display: flex;
  flex-wrap: wrap;
}

article.right input {
  width: 100%;
  height: 45px;
  background: var(--background-secondary);
  border: none;

  margin-bottom: 20px;
}

input,
textarea {
  width: 100%;
  background: var(--background-secondary);
  border: none;
  text-decoration: none;
  outline: none;
  color: var(--text-primary);
  font-size: 18px;
  padding: 10px 10px 5px 20px;
  font-weight: 600;
}

input:focus,
textarea:hover {
  width: 100%;
  background: var(--background-secondary);
  border: none;
}

article.right input {
  height: 50px;
  margin-bottom: 20px;
}

article.right textarea {
  height: 100px;
  margin: 0;
  resize: vertical;
}

button {
  font-weight: 600;
  background: var(--background-secondary);
  border: none;
  text-decoration: none;
  outline: none;
  color: var(--text-primary);
  font-size: 18px;
  padding: 15px;
  margin-top: 15px;
  float: right;
  cursor: pointer;
}

button:hover {
  background: var(--background-accent);
  color: var(--text-dark);
  transition-duration: 0.5s;
}

button:disabled {
  background: var(--background-accent);
  color: var(--text-dark);
  opacity: 0.5;
  cursor: not-allowed;
  transition-duration: 0.5s;
}

@media only screen and (max-width: 750px) {
  section {
    margin-top: 50px;
  }

  article {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  h2 {
    width: 100%;
    text-align: center;
  }

  section {
    flex-wrap: wrap;
  }

  article.left .logos {
    margin-top: 10px;
  }

  article.left .logos a:nth-child(3) img {
    margin-right: 0;
  }
  article.right {
    margin-top: 50px;
  }
}
</style>
