<template>
  <header>
    <nav>
      <router-link
        :to="{ name: 'about' }"
        :data-active="$route.name === 'about'"
      >
        A propos
      </router-link>
      <router-link
        :to="{ name: 'portfolio' }"
        :data-active="$route.name === 'portfolio'"
      >
        portfolio
      </router-link>
      <router-link
        :to="{ name: 'contact' }"
        :data-active="$route.name === 'contact'"
      >
        Contact
      </router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: "HeaderNav",
  mounted() {
    /**
     * Scroll Header
     */
    let headerScroll = 0;
    let lastScroll = window.scrollY;
    window.onscroll = function() {
      let up = lastScroll > this.scrollY;
      let scroll = window.scrollY - lastScroll;

      if (!up && headerScroll > -75 && window.scrollY > 0) {
        headerScroll = headerScroll - scroll;
        if (headerScroll < -75) headerScroll = -75;
        if (window.scrollY <= 0) headerScroll = 0;
      } else if (up && headerScroll < 0) {
        headerScroll = headerScroll - scroll;
        if (headerScroll > 0) headerScroll = 0;
        if (window.scrollY < 0) headerScroll = 0;
      }
      document.querySelector("header").style.top = headerScroll + "px";
      lastScroll = window.scrollY;
    };
  }
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 75px;
  background: linear-gradient(180deg, var(--background-main), transparent);
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
}
nav {
}

nav a {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 50px;
}
nav a[data-active] {
  color: var(--text-accent);
}
@media only screen and (max-width: 750px) {
  h1 {
    font-size: 35px;
  }
  header {
    justify-content: center;
    padding-right: 0;
    z-index: 99;
  }
  nav a {
    font-size: 16px;
    margin-left: 20px;
  }
  nav a:first-child {
    margin-left: 0;
  }
}
</style>
